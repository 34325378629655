import { Days } from './components/Form/types'

export const weekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
export const allDaysUngrouped = [...weekDays, 'SATURDAY', 'SUNDAY', 'HOLIDAY']
export const allDaysGrouped: Days[] = [
  'SATURDAY',
  'SUNDAY',
  'HOLIDAY',
  'WORKDAYS',
]
