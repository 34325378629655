import {
  Days,
  FormData,
  Periodicity,
} from 'domains/customer/screens/Contact/components/Form/types'
import { weekDays } from 'domains/customer/screens/Contact/constants'
import {
  ReportSchedulePeriodicity,
  ContactPayload,
} from 'services/contact/types'
import { DayOfWork } from 'services/types'

export const unGroupWorkDays = (days: Days[]): DayOfWork[] => {
  let unGroupWorkDays

  if (days.includes('WORKDAYS')) {
    unGroupWorkDays = [...days.filter((day) => day !== 'WORKDAYS'), ...weekDays]
  } else {
    unGroupWorkDays = days
  }

  return unGroupWorkDays as DayOfWork[]
}

export const groupWorkDays = (days: DayOfWork[]): Days[] => {
  const WORKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']

  const selectedWorkDays = days.filter((day) => WORKDAYS.includes(day))

  if (selectedWorkDays.length === WORKDAYS.length) {
    return [...days, 'WORKDAYS']
  } else {
    return days
  }
}

export const handleSetPeriodicity = (
  periodicity: Periodicity,
  days?: Days[],
): ReportSchedulePeriodicity[] => {
  if (periodicity === 'MONTHLY') return ['FIRST_DAY_OF_THE_MONTH']

  if (periodicity === 'DAILY')
    return [
      'EVERY_MONDAY',
      'EVERY_TUESDAY',
      'EVERY_WEDNESDAY',
      'EVERY_THURSDAY',
      'EVERY_FRIDAY',
      'EVERY_SATURDAY',
      'EVERY_SUNDAY',
    ]

  return days?.map((day) => `EVERY_${day}`) as ReportSchedulePeriodicity[]
}

type GetPeriodicity = { periodicity: Periodicity; days: Days[] }

export const handleGetPeriodicity = (
  periodicity: ReportSchedulePeriodicity[],
): GetPeriodicity => {
  if (periodicity.includes('FIRST_DAY_OF_THE_MONTH'))
    return { periodicity: 'MONTHLY', days: [] }

  const daily = [
    'EVERY_MONDAY',
    'EVERY_TUESDAY',
    'EVERY_WEDNESDAY',
    'EVERY_THURSDAY',
    'EVERY_FRIDAY',
    'EVERY_SATURDAY',
    'EVERY_SUNDAY',
  ]

  if (daily.length === periodicity.length)
    return { periodicity: 'DAILY', days: [] }

  return {
    periodicity: 'WEEKLY',
    days: periodicity.map((day) => day.replace(/^EVERY_/, '')),
  } as GetPeriodicity
}

export const converter = (data: FormData): ContactPayload => {
  const payload: ContactPayload = {
    accounts: data?.accounts?.map((account) => ({
      id: account.id,
      admin: account.admin,
    })),
    allActionPlans: data.allActionPlans,
    allPartitions: data.allPartitions,
    ubiAppUser: data.contact.ubiAppUser,
    name: data.contact.name,
    ...(!!data.contact?.email && {
      email: data.contact.email,
    }),
    phone: data.contact.phone,
    role: data.contact.role,
    customerId: data.customerId,
    messageConfigurations: Object.entries(data.messaging?.events || []).map(
      ([accountId, config]) => ({
        accountId,
        messageChannels: config.messageChannels || [],
        startTime: config.startTime,
        endTime: config.endTime,
        days: unGroupWorkDays(config.days),
        eventGroupIds: config.eventGroupIds || [],
      }),
    ),
    reportScheduleConfigurations: Object.entries(
      data.messaging?.reports || {},
    ).map(([accountId, config]) => ({
      id: config?.id,
      accountId,
      periodicity: handleSetPeriodicity(config.periodicity, config.days),
      parameters: config.parameters,
      reportTime: config.reportTime,
      reportType: 'ACCOUNT_EVENTS',
      startReportTime: config.startReportTime,
    })),
  }

  return payload
}
