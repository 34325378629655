import {
  ButtonV2,
  Checkbox,
  Datepicker,
  FormItem,
  FormLabel,
  Icon,
  SelectV2,
  TextField,
  Toggle,
  RadioButton,
  TooltipV2,
} from 'components'

import { useFormContext } from 'react-hook-form'

import { ChangeEvent, useCallback, useReducer, useState } from 'react'
import {
  Account,
  Days,
  FormData,
  Periodicity,
  days,
  periodicity,
} from '../../types'

import { useQueryEventGroup } from 'services/event'

import joinClassNames from 'utilities/joinClassNames'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'

import { formatTimeMask } from 'utilities/date'

import styles from './styles.module.scss'

const daysOptions = Object.entries(days).map(([key, value]) => ({
  label: value,
  value: key as Days,
}))

const periodicityOptions = Object.entries(periodicity).map(([key, value]) => ({
  label: value,
  value: key as Periodicity,
}))

const Reports = () => {
  const { watch, setValue, getFieldState } = useFormContext<FormData>()
  const { accounts, messaging } = watch()

  const [isEnable, toggle] = useReducer(
    (old) => !old,
    Boolean(Object.entries(messaging?.reports || []).length),
  )

  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)

  const report = messaging?.reports?.[selectedAccount?.id || '']

  const { data: eventGroups } = useQueryEventGroup()

  const handleChangeScheduleDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        setValue(
          `messaging.reports.${selectedAccount?.id}.startReportTime`,
          convertStringToDate(value).getTime(),
          { shouldValidate: true, shouldDirty: true },
        )
      }

      return value
    },
    [setValue],
  )

  const emailField = getFieldState('contact.email')

  return (
    <>
      <div className={styles.fields}>
        <FormItem className={styles.toggle}>
          <TooltipV2.Root>
            <TooltipV2.Trigger>
              <div>
                <Toggle
                  checked={isEnable && !!watch('contact.email')}
                  disabled={emailField.invalid || !watch('contact.email')}
                  onChange={() => {
                    if (watch('messaging.reports')) {
                      setValue('messaging.reports', undefined, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    } else {
                      setValue(
                        'messaging.reports',
                        {},
                        { shouldValidate: true, shouldDirty: true },
                      )
                    }

                    toggle()
                  }}
                />
              </div>
            </TooltipV2.Trigger>
            {(emailField.invalid || !watch('contact.email')) && (
              <TooltipV2.Content size="sm" position="bottom" offsetX={30}>
                Preencha o e-mail do contato para habilitar.
              </TooltipV2.Content>
            )}
          </TooltipV2.Root>

          <FormLabel>
            Habilitar envio automático de relatórios via e-mail
          </FormLabel>
        </FormItem>

        {isEnable && (
          <>
            <FormItem>
              <FormLabel>Conta</FormLabel>
              <SelectV2.Root
                valueKey="id"
                data={accounts?.map((account) => ({
                  ...account,
                  name: account.aggregatedName,
                }))}
              >
                {({ unSelectedOptions }) => {
                  return (
                    <>
                      <SelectV2.Field
                        placeholder="Selecione uma conta"
                        value={selectedAccount?.aggregatedName || ''}
                      />
                      <SelectV2.Group>
                        {unSelectedOptions?.map((option) => (
                          <SelectV2.Option
                            key={option.id}
                            hasLabel
                            onClick={() => {
                              setSelectedAccount(option)
                            }}
                          >
                            {option.aggregatedName}

                            {!!messaging?.reports?.[option.id || ''] && (
                              <span>Habilitado</span>
                            )}
                          </SelectV2.Option>
                        ))}
                      </SelectV2.Group>
                    </>
                  )
                }}
              </SelectV2.Root>
            </FormItem>

            <FormItem>
              <div className={styles.radio}>
                <FormLabel>Periodicidade</FormLabel>

                {periodicityOptions.map((option) => {
                  const value = option.value as Periodicity

                  return (
                    <RadioButton
                      key={option.label}
                      disabled={!selectedAccount}
                      name={option.label}
                      value={option.label}
                      checked={report?.periodicity === value}
                      onChange={() =>
                        setValue(
                          `messaging.reports.${selectedAccount?.id}.periodicity`,
                          option.value,
                        )
                      }
                    />
                  )
                })}
              </div>
            </FormItem>

            <FormItem>
              <div className={styles.date}>
                <div>
                  <FormLabel>Data de início</FormLabel>
                  <Datepicker
                    id="startReportTime"
                    placeholder="00/00/0000"
                    initialValueInput={
                      report?.startReportTime
                        ? convertDateToString(
                            new Date(report?.startReportTime || 0),
                          )
                        : undefined
                    }
                    initialDate={new Date()}
                    onChangeInput={handleChangeScheduleDateInput}
                    onChangeDate={(date) =>
                      setValue(
                        `messaging.reports.${selectedAccount?.id}.startReportTime`,
                        date?.getTime(),
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        },
                      )
                    }
                    selectEnd
                    disabled={!selectedAccount}
                  />
                </div>
                <div>
                  <FormLabel>Horário</FormLabel>
                  <TextField
                    value={report?.reportTime || ''}
                    disabled={!selectedAccount}
                    maxLength={5}
                    placeholder="00:00"
                    onChange={(event) =>
                      setValue(
                        `messaging.reports.${selectedAccount?.id}.reportTime`,
                        formatTimeMask(event.target.value),
                      )
                    }
                  />
                </div>
              </div>
            </FormItem>

            {report?.periodicity === 'WEEKLY' && (
              <FormItem>
                <div className={styles.days}>
                  <FormLabel>Detalhes do envio</FormLabel>
                  <div>
                    {daysOptions.slice(1, -1).map((option) => {
                      const isChecked = (report?.days || []).includes(
                        option.value,
                      )

                      return (
                        <Checkbox
                          key={option.value}
                          id={option.value}
                          label={option.label}
                          checked={!!isChecked}
                          small
                          disabled={!selectedAccount}
                          onChange={() => {
                            if (isChecked) {
                              setValue(
                                `messaging.reports.${selectedAccount?.id}.days`,
                                (report?.days || []).filter(
                                  (rpt) => rpt !== option.value,
                                ),
                                { shouldValidate: true, shouldDirty: true },
                              )
                            } else {
                              setValue(
                                `messaging.reports.${selectedAccount?.id}.days`,
                                [...(report?.days || []), option.value],
                                { shouldValidate: true, shouldDirty: true },
                              )
                            }
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              </FormItem>
            )}

            <ButtonV2
              onClick={() => {
                const updatedReports = { ...messaging?.reports }
                delete updatedReports[selectedAccount?.id || '']

                setValue(
                  'messaging.reports',
                  Object.values(updatedReports).length
                    ? updatedReports
                    : undefined,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                )
              }}
              appearance="tertiary"
            >
              <Icon name="delete" />
              Limpar seleções
            </ButtonV2>
          </>
        )}
      </div>

      <div className={joinClassNames(isEnable && styles.sectionRight)}>
        {isEnable && (
          <FormItem>
            <FormLabel>Tipo de eventos</FormLabel>
            <div className={styles.checkBoxes}>
              <Checkbox
                id="ALL"
                label="Todos"
                small
                disabled={!selectedAccount}
                onChange={() => {
                  if (
                    report?.parameters?.eventGroupIds?.length !==
                    eventGroups?.data?.length
                  ) {
                    setValue(
                      `messaging.reports.${selectedAccount?.id}.parameters.eventGroupIds`,
                      eventGroups?.data?.map((group) => group.id),
                      { shouldValidate: true, shouldDirty: true },
                    )
                  } else {
                    setValue(
                      `messaging.reports.${selectedAccount?.id}.parameters.eventGroupIds`,
                      [],
                      { shouldValidate: true, shouldDirty: true },
                    )
                  }
                }}
                checked={
                  report?.parameters?.eventGroupIds?.length ===
                  eventGroups?.data.length
                }
              />
              <div>
                {eventGroups?.data.map((option) => {
                  const isChecked = (
                    report?.parameters?.eventGroupIds || []
                  ).includes(option.id)

                  return (
                    <Checkbox
                      key={option.id}
                      id={option.id}
                      label={option.description}
                      checked={!!isChecked}
                      small
                      disabled={!selectedAccount}
                      onChange={() => {
                        if (isChecked) {
                          setValue(
                            `messaging.reports.${selectedAccount?.id}.parameters.eventGroupIds`,
                            (report?.parameters?.eventGroupIds || []).filter(
                              (evt) => evt !== option.id,
                            ),
                            { shouldValidate: true, shouldDirty: true },
                          )
                        } else {
                          setValue(
                            `messaging.reports.${selectedAccount?.id}.parameters.eventGroupIds`,
                            [
                              ...(report?.parameters?.eventGroupIds || []),
                              option.id,
                            ],
                            { shouldValidate: true, shouldDirty: true },
                          )
                        }
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </FormItem>
        )}
      </div>
    </>
  )
}

export default Reports
