import Joi from '@hapi/joi'
import { FormData } from 'domains/customer/screens/Contact/components/Form/types'
import { reportType } from 'services/contact/types'

export const schema = Joi.object<FormData>({
  allPartitions: Joi.boolean().optional(),
  allActionPlans: Joi.boolean().optional(),
  customerId: Joi.string().uuid().required(),
  contact: Joi.object({
    name: Joi.string().required().messages({
      'any.required': 'O preenchimento do nome é obrigatório.',
      'string.empty': 'O preenchimento do nome é obrigatório.',
    }),
    phone: Joi.object({
      provinceCode: Joi.number().integer().required(),
      number: Joi.number().integer().positive().required(),
      type: Joi.string().valid('W', 'H', 'C').required(),
    }).optional(),
    role: Joi.string().allow('', null).optional(),
    ubiAppUser: Joi.boolean().required(),
    email: Joi.string().when('ubiAppUser', {
      is: true,
      then: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      otherwise: Joi.string()
        .email({ tlds: { allow: false } })
        .allow('', null)
        .optional(),
    }),
  }).required(),
  accounts: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid().required(),
        code: Joi.string().allow('', null).required(),
        admin: Joi.boolean().required(),
        actionable: Joi.boolean().optional(),
        hasControl: Joi.boolean().optional(),
        isCentralUser: Joi.boolean().optional(),
        serviceType: Joi.object({
          name: Joi.string().allow('', null).required(),
          color: Joi.string().allow('', null).required(),
        }).optional(),
        aggregatedName: Joi.string().required(),
      }),
    )
    .optional(),
  messaging: Joi.object({
    events: Joi.object()
      .pattern(
        Joi.string().uuid().required(),
        Joi.object({
          endTime: Joi.string().required(),
          startTime: Joi.string().required(),
          messageChannels: Joi.array()
            .items(Joi.string().valid('SMS', 'EMAIL'))
            .min(1)
            .required(),
          days: Joi.array()
            .items(
              Joi.string().valid(
                'WORKDAYS',
                'MONDAY',
                'TUESDAY',
                'WEDNESDAY',
                'THURSDAY',
                'FRIDAY',
                'SATURDAY',
                'SUNDAY',
                'HOLIDAY',
              ),
            )
            .min(1)
            .required(),
          eventGroupIds: Joi.array()
            .items(Joi.string().uuid())
            .min(1)
            .required(),
        }).required(),
      )
      .custom((value, helpers) => {
        const keys = Object.keys(value || {})
        if (keys.length === 0) {
          return helpers.error('object.min', { limit: 1 })
        }

        for (const key of keys) {
          if (!value[key] || Object.keys(value[key]).length === 0) {
            return helpers.error('object.empty', { key })
          }
        }

        return value
      })
      .min(1)
      .optional(),
    reports: Joi.object()
      .pattern(
        Joi.string(),
        Joi.object({
          id: Joi.string().uuid().optional(),
          contactId: Joi.string().uuid().optional(),
          reportType: Joi.string().valid(...Object.values(reportType)),
          parameters: Joi.object({
            eventGroupIds: Joi.array().items(Joi.string().uuid()),
          }).optional(),
          startReportTime: Joi.number().required(),
          reportTime: Joi.string()
            .pattern(/^([01]\d|2[0-3]):([0-5]\d)$/)
            .required(),
          periodicity: Joi.string()
            .valid('DAILY', 'WEEKLY', 'MONTHLY')
            .required(),
          days: Joi.array().when('periodicity', {
            is: 'WEEKLY',
            then: Joi.array()
              .items(
                Joi.string().valid(
                  'MONDAY',
                  'TUESDAY',
                  'WEDNESDAY',
                  'THURSDAY',
                  'FRIDAY',
                  'SATURDAY',
                  'SUNDAY',
                ),
              )
              .min(1)
              .required(),
            otherwise: Joi.array().optional(),
          }),
        }),
      )
      .custom((value, helpers) => {
        const keys = Object.keys(value || {})
        if (keys.length === 0) {
          return helpers.error('object.min', { limit: 1 })
        }

        for (const key of keys) {
          if (!value[key] || Object.keys(value[key]).length === 0) {
            return helpers.error('object.empty', { key })
          }
        }

        return value
      })
      .optional(),
  }).optional(),
})
