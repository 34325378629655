import { Days } from 'domains/customer/screens/Contact/components/Form/types'
import {
  allDaysGrouped,
  allDaysUngrouped,
} from 'domains/customer/screens/Contact/constants'

export const handleWeekDaysField = (days: Days[]) => {
  const allDaysGroupedSelected =
    days.length === allDaysGrouped.length &&
    days.every((day) => allDaysGrouped.includes(day))

  const allDaysUnGroupedSelected =
    days.length === allDaysUngrouped.length &&
    days.every((day) => allDaysUngrouped.includes(day))

  return allDaysGroupedSelected || allDaysUnGroupedSelected || false
}

export const handleHoursAndMinutes = () => {
  const hoursAndMinutes = []

  let hour = 0
  let minutes = 0

  while (hour <= 23) {
    const formattedHoursAndMinutes = `${hour
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

    hoursAndMinutes.push({
      name: formattedHoursAndMinutes,
      value: formattedHoursAndMinutes,
    })

    minutes += 30
    if (minutes === 60) {
      minutes = 0
      hour++
    }
  }

  return hoursAndMinutes
}
