import {
  FormItem,
  FormLabel,
  Icon,
  TextField,
  Toggle,
  TooltipV2,
} from 'components'
import {
  EventConfiguration,
  FormData,
} from 'domains/customer/screens/Contact/components/Form/types'
import { useFormContext } from 'react-hook-form'
import joinClassNames from 'utilities/joinClassNames'
import styles from './styles.module.scss'
import { useRef, useState } from 'react'
import { formatGenericPhone } from 'domains/serviceOrders/utilities/mask/phone'
import {
  EmailAlert,
  EventAlert,
} from 'domains/customer/screens/Contact/components/Form/components/Modals'
import { PhoneType } from 'services/contact/types'

const Identification = () => {
  const [alertModals, setAlertModals] = useState<'phone' | 'email' | null>(null)

  const {
    watch,
    register,
    setValue,
    formState: { errors },
    getFieldState,
  } = useFormContext<FormData>()

  const { contact, messaging } = watch()

  const phoneFieldRef = useRef<HTMLInputElement>(null)
  const emailFieldRef = useRef<HTMLInputElement>(null)

  const emailField = getFieldState('contact.email')
  const nameError = errors.contact?.name?.message

  return (
    <div>
      {alertModals === 'phone' && (
        <EventAlert
          onClose={() => {
            setAlertModals(null)
            phoneFieldRef.current?.focus()
          }}
          onPress={() => {
            const eventConfigList = Object.entries(
              watch('messaging.events') || [],
            )
            const handleEvents = eventConfigList
              ?.map(([accountId, config]) => ({
                ...config,
                accountId,
                messageChannels:
                  config?.messageChannels?.filter(
                    (channel) => channel !== 'SMS',
                  ) || [],
              }))
              .filter((event) => !!event.messageChannels.length)

            const normalizedEvents = handleEvents?.reduce<EventConfiguration>(
              (result, { accountId, ...config }) => {
                result[accountId] = {
                  ...config,
                }
                return result
              },
              {},
            )

            setValue(
              'messaging.events',
              handleEvents?.length ? normalizedEvents : undefined,
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            )

            setAlertModals(null)
          }}
        />
      )}

      {alertModals === 'email' && (
        <EmailAlert
          onClose={() => {
            setAlertModals(null)
            emailFieldRef.current?.focus()
          }}
          onPress={() => {
            const eventConfigList = Object.entries(
              watch('messaging.events') || [],
            )
            const handleEvents = eventConfigList
              ?.map(([accountId, config]) => ({
                ...config,
                accountId,
                messageChannels:
                  config?.messageChannels?.filter(
                    (channel) => channel !== 'EMAIL',
                  ) || [],
              }))
              .filter((event) => !!event.messageChannels.length)

            const normalizedEvents = handleEvents?.reduce<EventConfiguration>(
              (result, { accountId, ...config }) => {
                result[accountId] = {
                  ...config,
                }
                return result
              },
              {},
            )

            setValue('messaging.reports', undefined, {
              shouldValidate: true,
              shouldDirty: true,
            })

            setValue(
              'messaging.events',
              handleEvents?.length ? normalizedEvents : undefined,
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            )

            setAlertModals(null)
          }}
        />
      )}

      <FormItem>
        <FormLabel>Nome</FormLabel>
        <TextField
          {...register('contact.name')}
          onChange={(event) => {
            setValue('contact.name', event.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }}
          className={joinClassNames(!!nameError && styles.borderError)}
        />

        {!!nameError && (
          <span className={styles.errorWrapper}>
            <Icon name="close-bold" width={8} />
            <p className={styles.errorText}>{nameError}</p>
          </span>
        )}
      </FormItem>
      <FormItem>
        <FormLabel>Função</FormLabel>
        <TextField {...register('contact.role')} />
      </FormItem>
      <FormItem>
        <FormLabel>Telefone</FormLabel>
        <TextField
          ref={phoneFieldRef}
          maxLength={15}
          value={
            contact?.phone
              ? formatGenericPhone(
                  String(contact.phone?.provinceCode || '') +
                    String(contact.phone?.number || ''),
                )
              : ''
          }
          onBlur={(event) => {
            if (!event.target.value) {
              setValue('contact.phone', undefined, {
                shouldValidate: true,
                shouldDirty: true,
              })
              setValue('allActionPlans', false, {
                shouldValidate: true,
                shouldDirty: true,
              })

              const hasSMSConfiguration = Object.entries(
                watch('messaging.events') || [],
              ).some(([_accountId, config]) =>
                config.messageChannels?.includes('SMS'),
              )

              if (hasSMSConfiguration) {
                setAlertModals('phone')
              }
            }
          }}
          onChange={(event) => {
            setValue(
              'contact.phone',
              {
                provinceCode: Number(
                  event.target?.value.replace(/\D/g, '').substring(0, 2),
                ),
                number: Number(
                  event.target?.value.replace(/\D/g, '').substring(2),
                ),
                type: PhoneType.CellPhone,
              },
              { shouldValidate: true, shouldDirty: true },
            )
          }}
        />
      </FormItem>
      <FormItem>
        <FormLabel>E-mail</FormLabel>
        <TextField
          ref={emailFieldRef}
          value={watch('contact.email')}
          onBlur={(event) => {
            setValue('contact.ubiAppUser', false, {
              shouldValidate: true,
              shouldDirty: true,
            })

            setValue('contact.email', event.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })

            if (!event.target.value) {
              const hasEventEmailConfiguration = Object.entries(
                messaging?.events || [],
              ).some(([_accountId, config]) =>
                config.messageChannels?.includes('EMAIL'),
              )

              const reportConfigurations = Object.entries(
                messaging?.reports || [],
              )

              if (
                hasEventEmailConfiguration ||
                !!reportConfigurations?.length
              ) {
                setAlertModals('email')
              }
            }
          }}
          onChange={(event) => {
            setValue('contact.email', event.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }}
        />
      </FormItem>
      <FormItem className={styles.toggle}>
        <TooltipV2.Root>
          <TooltipV2.Trigger>
            <div>
              <Toggle
                {...register('contact.ubiAppUser')}
                disabled={emailField.invalid || !watch('contact.email')}
                onChange={(event) =>
                  setValue('contact.ubiAppUser', event.target.checked, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
              />
            </div>
          </TooltipV2.Trigger>
          {(emailField.invalid || !watch('contact.email')) && (
            <TooltipV2.Content size="sm" position="top" offsetX={18}>
              Preencha o e-mail do contato para habilitar.
            </TooltipV2.Content>
          )}
        </TooltipV2.Root>

        <FormLabel>Usuário do UbiApp</FormLabel>
      </FormItem>
    </div>
  )
}

export default Identification
