import {
  ButtonV2,
  Checkbox,
  FormItem,
  FormLabel,
  Icon,
  SelectV2,
  Toggle,
  TooltipV2,
} from 'components'

import { useFormContext } from 'react-hook-form'
import { useMemo, useReducer, useState } from 'react'
import { Account, Days, FormData, days } from '../../types'

import { useQueryEventGroup } from 'services/event'

import {
  allDaysGrouped,
  weekDays,
} from 'domains/customer/screens/Contact/constants'

import {
  handleHoursAndMinutes,
  handleWeekDaysField,
} from 'domains/customer/screens/Contact/components/Form/components/Events/utilities'

import joinClassNames from 'utilities/joinClassNames'

import styles from './styles.module.scss'

const daysOptions = Object.entries(days).map(([key, value]) => ({
  label: value,
  value: key as Days,
}))

const Events = () => {
  const { watch, setValue, getFieldState } = useFormContext<FormData>()
  const { accounts, messaging } = watch()

  const [isEnable, toggle] = useReducer(
    (old) => !old,
    Boolean(Object.entries(messaging?.events || []).length),
  )

  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)

  const event = messaging?.events?.[selectedAccount?.id || '']

  const { data: eventGroups } = useQueryEventGroup()

  const allDaysSelected = useMemo(
    () => handleWeekDaysField(event?.days || []),
    [event?.days],
  )

  const phoneField = getFieldState('contact.phone')
  const emailField = getFieldState('contact.email')

  const shouldDisableSMSField =
    !selectedAccount || phoneField.invalid || !watch('contact.phone')

  return (
    <>
      <div className={styles.fields}>
        <FormItem className={styles.toggle}>
          <Toggle
            checked={isEnable}
            onChange={() => {
              if (watch('messaging.events')) {
                setValue('messaging.events', undefined, {
                  shouldValidate: true,
                })
              } else {
                setValue(
                  'messaging.events',
                  {},
                  { shouldValidate: true, shouldDirty: true },
                )
              }

              toggle()
            }}
          />
          <FormLabel>Habilitar envio de alertas de eventos</FormLabel>
        </FormItem>

        {isEnable && (
          <>
            <FormItem>
              <FormLabel>Conta</FormLabel>
              <SelectV2.Root
                valueKey="id"
                data={accounts?.map((account) => ({
                  ...account,
                  name: account.aggregatedName,
                }))}
              >
                {({ unSelectedOptions }) => {
                  return (
                    <>
                      <SelectV2.Field
                        placeholder="Selecione uma conta"
                        value={selectedAccount?.aggregatedName || ''}
                      />
                      <SelectV2.Group>
                        {unSelectedOptions?.map((option) => (
                          <SelectV2.Option
                            key={option.id}
                            hasLabel
                            onClick={() => {
                              setSelectedAccount(option)
                            }}
                          >
                            {option.aggregatedName}

                            {!!messaging?.events?.[option.id || ''] && (
                              <span>Habilitado</span>
                            )}
                          </SelectV2.Option>
                        ))}
                      </SelectV2.Group>
                    </>
                  )
                }}
              </SelectV2.Root>
            </FormItem>

            <FormItem>
              <FormLabel>Horário de monitoramento</FormLabel>
              <div className={styles.monitoring}>
                <small>de</small>
                <SelectV2.Root valueKey="value" data={handleHoursAndMinutes()}>
                  {({ unSelectedOptions }) => (
                    <>
                      <SelectV2.Field
                        placeholder="00:00"
                        disabled={!selectedAccount}
                        value={String(
                          watch(
                            `messaging.events.${selectedAccount?.id}.startTime`,
                          ) || '',
                        )}
                      />
                      <SelectV2.Group>
                        {unSelectedOptions?.map((option) => (
                          <SelectV2.Option
                            key={option.value}
                            onClick={() =>
                              setValue(
                                `messaging.events.${selectedAccount?.id}.startTime`,
                                option.value,
                                { shouldValidate: true, shouldDirty: true },
                              )
                            }
                          >
                            {option.name}
                          </SelectV2.Option>
                        ))}
                      </SelectV2.Group>
                    </>
                  )}
                </SelectV2.Root>
                <small>às</small>
                <SelectV2.Root valueKey="value" data={handleHoursAndMinutes()}>
                  {({ unSelectedOptions }) => (
                    <>
                      <SelectV2.Field
                        placeholder="00:00"
                        disabled={!selectedAccount}
                        value={String(
                          watch(
                            `messaging.events.${selectedAccount?.id}.endTime`,
                          ) || '',
                        )}
                      />
                      <SelectV2.Group>
                        {unSelectedOptions?.map((option) => (
                          <SelectV2.Option
                            key={option.value}
                            onClick={() =>
                              setValue(
                                `messaging.events.${selectedAccount?.id}.endTime`,
                                option.value,
                                { shouldValidate: true, shouldDirty: true },
                              )
                            }
                          >
                            {option.name}
                          </SelectV2.Option>
                        ))}
                      </SelectV2.Group>
                    </>
                  )}
                </SelectV2.Root>
              </div>
            </FormItem>

            <FormItem>
              <FormLabel>Tipo de alerta</FormLabel>

              <div className={styles.toggleGroup}>
                <FormItem className={styles.toggle}>
                  <TooltipV2.Root>
                    <TooltipV2.Trigger>
                      <div>
                        <Toggle
                          disabled={shouldDisableSMSField}
                          checked={event?.messageChannels?.some(
                            (channel) => channel === 'SMS',
                          )}
                          onChange={({ target: { checked } }) => {
                            let channels = event?.messageChannels || []

                            if (!checked) {
                              channels = channels.filter(
                                (channel) => channel !== 'SMS',
                              )
                            } else {
                              channels.push('SMS')
                            }

                            return setValue(
                              `messaging.events.${selectedAccount?.id}.messageChannels`,
                              channels,
                              { shouldValidate: true, shouldDirty: true },
                            )
                          }}
                        />
                      </div>
                    </TooltipV2.Trigger>
                    {shouldDisableSMSField && (
                      <TooltipV2.Content
                        size="sm"
                        position="bottom"
                        offsetX={30}
                      >
                        Preencha o telefone do contato para habilitar.
                      </TooltipV2.Content>
                    )}
                  </TooltipV2.Root>

                  <FormLabel>SMS</FormLabel>
                </FormItem>

                <FormItem className={styles.alertType}>
                  <TooltipV2.Root>
                    <TooltipV2.Trigger>
                      <div>
                        <Toggle
                          disabled={
                            !selectedAccount ||
                            emailField.invalid ||
                            !watch('contact.email')
                          }
                          checked={event?.messageChannels?.some(
                            (channel) => channel === 'EMAIL',
                          )}
                          onChange={({ target: { checked } }) => {
                            let channels = event?.messageChannels || []

                            if (!checked) {
                              channels = channels.filter(
                                (channel) => channel !== 'EMAIL',
                              )
                            } else {
                              channels.push('EMAIL')
                            }

                            return setValue(
                              `messaging.events.${selectedAccount?.id}.messageChannels`,
                              channels,
                              { shouldValidate: true, shouldDirty: true },
                            )
                          }}
                        />
                      </div>
                    </TooltipV2.Trigger>
                    {(emailField.invalid || !watch('contact.email')) && (
                      <TooltipV2.Content
                        size="sm"
                        position="bottom"
                        offsetX={30}
                      >
                        Preencha o e-mail do contato para habilitar.
                      </TooltipV2.Content>
                    )}
                  </TooltipV2.Root>

                  <FormLabel>E-mail</FormLabel>
                </FormItem>
              </div>
            </FormItem>

            <FormItem>
              <FormLabel>Dias da semana</FormLabel>
              <div className={styles.days}>
                <Checkbox
                  id="ALL"
                  label="Todos"
                  small
                  disabled={!selectedAccount}
                  checked={allDaysSelected}
                  onChange={() => {
                    const value = allDaysSelected ? [] : allDaysGrouped

                    setValue(
                      `messaging.events.${selectedAccount?.id}.days`,
                      value,
                      { shouldValidate: true, shouldDirty: true },
                    )
                  }}
                />
                <div>
                  {daysOptions.map((option) => {
                    const isChecked = (event?.days || []).includes(option.value)

                    return (
                      <Checkbox
                        key={option.value}
                        id={option.value}
                        label={option.label}
                        checked={isChecked}
                        small
                        disabled={
                          !selectedAccount ||
                          (event?.days?.includes('WORKDAYS') &&
                            weekDays.includes(option.value))
                        }
                        onChange={() => {
                          const currentDays = event?.days || []
                          const isWorkDayOption = option.value === 'WORKDAYS'

                          const updatedDays = isChecked
                            ? currentDays.filter((day) => day !== option.value)
                            : [
                                ...(isWorkDayOption
                                  ? currentDays.filter((day) =>
                                      weekDays.includes(day),
                                    )
                                  : currentDays),
                                option.value,
                              ]

                          setValue(
                            `messaging.events.${selectedAccount?.id}.days`,
                            updatedDays,
                            { shouldValidate: true },
                          )
                        }}
                      />
                    )
                  })}
                </div>
              </div>
              <ButtonV2
                disabled={!selectedAccount}
                onClick={() => {
                  const updatedEvents = { ...messaging?.events }
                  delete updatedEvents[selectedAccount?.id || '']

                  setValue(
                    'messaging.events',
                    Object.values(updatedEvents).length
                      ? updatedEvents
                      : undefined,
                    {
                      shouldValidate: true,
                    },
                  )
                }}
                appearance="tertiary"
              >
                <Icon name="delete" />
                Limpar seleções
              </ButtonV2>
            </FormItem>
          </>
        )}
      </div>
      <div className={joinClassNames(isEnable && styles.sectionRight)}>
        {isEnable && (
          <FormItem>
            <FormLabel>Tipo de eventos</FormLabel>
            <div className={styles.checkBoxes}>
              <Checkbox
                id="ALL"
                label="Todos"
                small
                disabled={!selectedAccount}
                onChange={() => {
                  if (
                    event?.eventGroupIds?.length !== eventGroups?.data?.length
                  ) {
                    setValue(
                      `messaging.events.${selectedAccount?.id}.eventGroupIds`,
                      eventGroups?.data?.map((group) => group.id),
                      { shouldValidate: true, shouldDirty: true },
                    )
                  } else {
                    setValue(
                      `messaging.events.${selectedAccount?.id}.eventGroupIds`,
                      [],
                      { shouldValidate: true, shouldDirty: true },
                    )
                  }
                }}
                checked={
                  event?.eventGroupIds?.length === eventGroups?.data.length
                }
              />
              <div>
                {eventGroups?.data.map((option) => {
                  const isChecked = (event?.eventGroupIds || []).includes(
                    option.id,
                  )

                  return (
                    <Checkbox
                      key={option.id}
                      id={option.id}
                      label={option.description}
                      checked={!!isChecked}
                      small
                      disabled={!selectedAccount}
                      onChange={() => {
                        if (isChecked) {
                          setValue(
                            `messaging.events.${selectedAccount?.id}.eventGroupIds`,
                            (event?.eventGroupIds || []).filter(
                              (evt) => evt !== option.id,
                            ),
                            { shouldValidate: true, shouldDirty: true },
                          )
                        } else {
                          setValue(
                            `messaging.events.${selectedAccount?.id}.eventGroupIds`,
                            [...(event?.eventGroupIds || []), option.id],
                            { shouldValidate: true, shouldDirty: true },
                          )
                        }
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </FormItem>
        )}
      </div>
    </>
  )
}

export default Events
