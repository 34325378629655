import { ButtonV2, ModalV2 } from 'components'

import styles from './styles.module.scss'

interface AlertProps {
  onClose: () => void
  onPress: () => void
}
const UnsaveAlert = ({ onPress, onClose }: AlertProps) => {
  return (
    <ModalV2.Root isOpen onClose={onClose}>
      <ModalV2.Content size="sm" className={styles.container}>
        <ModalV2.Title>Alterações não salvas</ModalV2.Title>
        <p>
          Tem certeza que deseja retornar à página anterior? Todas as
          informações preenchidas serão perdidas, e essa ação não pode ser
          desfeita.
        </p>

        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2 appearance="primary" color="red" onClick={onPress}>
            Continuar
          </ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default UnsaveAlert
