import { ButtonV2, ModalV2 } from 'components'

import styles from './styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { FormData } from 'domains/customer/screens/Contact/components/Form/types'

interface AlertProps {
  onClose: () => void
  onPress: () => void
}
const EventAlert = ({ onPress, onClose }: AlertProps) => {
  const { watch } = useFormContext<FormData>()
  const { accounts, messaging } = watch()

  const eventAccounts = Object.entries(messaging?.events || []).map(
    ([accountId, _config]) => accountId,
  )

  const affectedAccounts = accounts.filter((account) =>
    eventAccounts.includes(account.id),
  )

  return (
    <ModalV2.Root isOpen onClose={onClose}>
      <ModalV2.Content size="sm" className={styles.container}>
        <ModalV2.Title>Remover dados do contato</ModalV2.Title>
        <p>
          Tem certeza de que deseja remover o telefone deste contato? Esta ação
          encerrará o envio de mensagens automáticas sobre as contas:
        </p>

        <ul>
          {affectedAccounts.slice(0, 3).map((account) => (
            <li key={account.id}>{account.aggregatedName}</li>
          ))}

          {affectedAccounts.length > 3 && (
            <li>E mais {affectedAccounts.length - 3} contas.</li>
          )}
        </ul>

        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2 appearance="primary" color="red" onClick={onPress}>
            Continuar
          </ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default EventAlert
