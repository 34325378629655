import { useState } from 'react'
import { ButtonV2, Icon, Section } from 'components'

import styles from './styles.module.scss'
import joinClassNames from 'utilities/joinClassNames'
import { FormProvider, useForm } from 'react-hook-form'

import {
  Events,
  Identification,
  Linking,
  Reports,
  UnsaveAlert,
} from './components'
import { FormData } from './types'

import { useNavigate } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import { schema } from './schemas/form'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { useToggle } from 'shared/hooks'

export interface Props {
  onSubmit: (data: FormData) => void
  defaultValues?: FormData
}

const Form = ({ onSubmit, defaultValues }: Props) => {
  const { customer } = useCustomerContext()
  const navigate = useNavigate()

  const unsaveAlert = useToggle()

  const [advancedOptions, setAdvancedOptions] = useState(false)

  const form = useForm<FormData>({
    defaultValues: {
      customerId: customer?.id || '',
      contact: {
        ubiAppUser: false,
      },
      ...defaultValues,
    },
    mode: 'all',
    resolver: joiResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = form

  return (
    <FormProvider {...form}>
      {unsaveAlert.isVisible && (
        <UnsaveAlert
          onClose={() => unsaveAlert.hide()}
          onPress={() => navigate(-1)}
        />
      )}

      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Section.Root index="1">
            <Section.Title>Dados gerais</Section.Title>
            <Section.SubTitle>
              Informações básicas e de identificação do contato
            </Section.SubTitle>
          </Section.Root>

          <Identification />
        </section>
        <section>
          <Section.Root index="2">
            <Section.Title>Vinculação</Section.Title>
            <Section.SubTitle>
              Definição das contas às quais este contato pertence
            </Section.SubTitle>
          </Section.Root>

          <Linking />
        </section>

        <ButtonV2
          appearance="tertiary"
          className={joinClassNames(
            styles.arrow,
            advancedOptions && styles.iconRotate,
          )}
          onClick={() => setAdvancedOptions((old) => !old)}
        >
          <Icon name="chevron-sm-down" />
          Opções avançadas
        </ButtonV2>
        {advancedOptions && (
          <section>
            <Section.Root index="3">
              <Section.Title>Mensageria</Section.Title>
              <Section.SubTitle>
                Opções de envio de alertas e relatórios periódicos via e-mail e
                SMS
              </Section.SubTitle>
            </Section.Root>

            <div>
              <Events />

              <Reports />
            </div>
          </section>
        )}

        <footer>
          <ButtonV2
            onClick={() => {
              if (isDirty) {
                unsaveAlert.show()
                return
              }

              navigate(-1)
            }}
            appearance="tertiary"
          >
            Voltar
          </ButtonV2>
          <ButtonV2 disabled={!isValid} type="submit">
            Salvar
          </ButtonV2>
        </footer>
      </form>
    </FormProvider>
  )
}

export default Form
