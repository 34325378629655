import {
  ButtonV2,
  ComboBoxV3,
  DropDown,
  FormItem,
  FormLabel,
  Icon,
  IconButton,
  Tag,
  Toggle,
  TooltipV2,
} from 'components'

import {
  FormData,
  Icons,
  Account,
  EventConfiguration,
  ReportScheduleConfiguration,
} from 'domains/customer/screens/Contact/components/Form/types'
import { useFormContext } from 'react-hook-form'

import styles from './styles.module.scss'
import { useCallback } from 'react'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'

const Linking = () => {
  const { customer } = useCustomerContext()

  const { watch, register, setValue } = useFormContext<FormData>()

  const { accounts = [] } = watch()

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) =>
      await fetchAccounts(offset, 15, customer?.id, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      }),
    [fetchAccounts, customer?.id],
  )

  const handleAdmin = useCallback((account: Account, accounts: Account[]) => {
    setValue('accounts', [
      ...accounts.map((acc) =>
        acc.id === account.id ? { ...account, admin: !account.admin } : acc,
      ),
    ])
  }, [])

  const handleRemoveAccount = useCallback(
    (
      account: Account,
      accounts: Account[],
      events?: EventConfiguration,
      reports?: ReportScheduleConfiguration,
    ) => {
      setValue(
        'accounts',
        accounts.filter((ac) => ac.id !== account.id),
        { shouldDirty: true, shouldValidate: true },
      )

      if (events && account.id in events) {
        const updatedEvents = { ...events }
        delete updatedEvents[account.id]

        const emptyEvent = !!Object.keys(events)?.length

        setValue('messaging.events', emptyEvent ? undefined : updatedEvents, {
          shouldDirty: true,
          shouldValidate: true,
        })
      }

      if (reports && account.id in reports) {
        const updatedReports = { ...reports }
        delete updatedReports[account.id]

        const emptyReport = !!Object.keys(reports)?.length

        setValue(
          'messaging.reports',
          emptyReport ? undefined : updatedReports,
          {
            shouldDirty: true,
            shouldValidate: true,
          },
        )
      }
    },
    [],
  )

  return (
    <div>
      <div className={styles.fields}>
        <FormItem>
          <FormLabel>Vincular conta</FormLabel>
          <ComboBoxV3.Root findOptions={handleGetAccounts} valueKey="id">
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field
                  placeholder="Selecione uma conta"
                  value={accounts}
                />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map((option) => (
                    <ComboBoxV3.Option
                      key={option.id}
                      shouldCloseGroup={false}
                      onClick={() => {
                        setValue(
                          'accounts',
                          [
                            ...(accounts || []),
                            {
                              id: option.id,
                              code: option?.code || '',
                              aggregatedName: option.aggregatedAccountName,
                              serviceType: {
                                name: option?.serviceType?.name || '',
                                color: option?.serviceType?.color || '',
                              },
                              admin: false,
                            },
                          ],
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                          },
                        )
                      }}
                    >
                      {option.aggregatedAccountName}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
          <ButtonV2 appearance="tertiary" size="md">
            <Icon name="add" />
            Adicionar à lista
          </ButtonV2>
        </FormItem>

        <div className={styles.linking}>
          <h4>Detalhes de vinculação</h4>

          <FormItem className={styles.toggle}>
            <Toggle
              {...register('allPartitions')}
              onChange={(event) => {
                if (!event.target.checked) {
                  setValue('allActionPlans', false, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }

                setValue('allPartitions', event.target.checked)
              }}
            />
            <FormLabel>
              Adicionar este contato a todas as partições das contas a serem
              vinculadas.
            </FormLabel>
          </FormItem>
          <FormItem className={styles.toggle}>
            <TooltipV2.Root>
              <TooltipV2.Trigger>
                <div>
                  <Toggle
                    {...register('allActionPlans')}
                    disabled={
                      !watch('allPartitions') || !watch('contact.phone')
                    }
                    onChange={(event) =>
                      setValue('allActionPlans', event.target.checked, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                </div>
              </TooltipV2.Trigger>
              {(!watch('allPartitions') || !watch('contact.phone')) && (
                <TooltipV2.Content size="sm" position="bottom" offsetX={30}>
                  Ative a opção acima e preencha o telefone do contato para
                  habilitar.
                </TooltipV2.Content>
              )}
            </TooltipV2.Root>

            <FormLabel>
              Adicionar este contato a todos os planos de ação das contas
              vinculadas.
            </FormLabel>
          </FormItem>
        </div>
      </div>

      <div className={styles.accounts}>
        <h3>Lista de contas vinculadas</h3>
        <ul>
          {accounts?.map((account) => {
            const icons: Icons[] = [
              {
                name: 'admin-user',
                describe: 'Contato administrador',
                on: account.admin,
              },
              {
                name: 'user-central',
                describe: 'Usuário da central',
                on: account.isCentralUser,
              },
              {
                name: 'control',
                describe: 'Usuário com controle',
                on: account.hasControl,
              },
              {
                name: 'action-plan-on',
                describe: 'Contato do plano de ação',
                on: account.actionable,
              },
            ]

            return (
              <li key={account.id}>
                <div>
                  <span>
                    {account.aggregatedName}

                    {icons.map(({ name, describe, on }) => {
                      if (on)
                        return (
                          <TooltipV2.Root key={name}>
                            <TooltipV2.Trigger>
                              <Icon
                                name={name}
                                width={12}
                                color="accent-brand-default"
                              />
                            </TooltipV2.Trigger>
                            <TooltipV2.Content position="bottom">
                              {describe}
                            </TooltipV2.Content>
                          </TooltipV2.Root>
                        )
                    })}
                  </span>

                  <Tag
                    color="green"
                    {...(account.serviceType?.color && {
                      style: {
                        backgroundColor: account.serviceType?.color,
                        color: account.serviceType?.color,
                      },
                    })}
                    singleColor={!!account.serviceType?.color}
                    className={styles.serviceType}
                  >
                    {account.serviceType?.name}
                  </Tag>
                </div>
                <div className={styles.rightSection}>
                  <DropDown.Root>
                    <DropDown.Trigger>
                      <IconButton>
                        <Icon name="menu-kebab" color="element-default" />
                      </IconButton>
                    </DropDown.Trigger>
                    <DropDown.Content>
                      <DropDown.Item
                        onClick={() =>
                          handleRemoveAccount(
                            account,
                            accounts,
                            watch('messaging.events'),
                            watch('messaging.reports'),
                          )
                        }
                      >
                        Desvincular desta conta
                      </DropDown.Item>
                      <DropDown.Item
                        type="button"
                        onClick={() => handleAdmin(account, accounts)}
                      >
                        {account.admin
                          ? 'Remover administrador'
                          : 'Tornar administrador'}
                      </DropDown.Item>
                    </DropDown.Content>
                  </DropDown.Root>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Linking
