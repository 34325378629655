import { IconName } from 'components/Icon/icon-names'
import { MessageChannel, PhoneType } from 'services/contact/types'

export const days = {
  WORKDAYS: 'Dias úteis',
  MONDAY: 'Segunda-feira',
  TUESDAY: 'Terça-feira',
  WEDNESDAY: 'Quarta-feira',
  THURSDAY: 'Quinta-feira',
  FRIDAY: 'Sexta-feira',
  SATURDAY: 'Sábado',
  SUNDAY: 'Domingo',
  HOLIDAY: 'Considerar feriados',
}

export const periodicity = {
  DAILY: 'Diária',
  WEEKLY: 'Semanal',
  MONTHLY: 'Mensal',
}

export type Days = keyof typeof days
export type Periodicity = keyof typeof periodicity
export type Events = 'ALARM' | 'DISARM'

export type Account = {
  id: string
  code: string
  admin: boolean
  actionable?: boolean
  hasControl?: boolean
  serviceType?: {
    name: string
    color: string
  }
  aggregatedName: string
  isCentralUser?: boolean
}

export type ReportScheduleParameter = {
  eventGroupIds?: string[]
}

export const reportType = {
  ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
  ACCOUNT_EVENTS: 'ACCOUNT_EVENTS',
  SERVICE_ORDER_DETAIL: 'SERVICE_ORDER_DETAIL',
  SERVICE_ORDER_FORM: 'SERVICE_ORDER_FORM',
}

export type ReportType = keyof typeof reportType

export type ReportScheduleConfiguration = Record<
  string, // accountId
  {
    id?: string
    contactId?: string
    reportType: ReportType
    startReportTime?: number
    days: Days[]
    reportTime: string
    parameters: ReportScheduleParameter
    periodicity: Periodicity
  }
>

export type EventConfiguration = Record<
  string, // accountId
  {
    endTime: string
    startTime: string
    messageChannels?: MessageChannel[]
    days: Days[]
    eventGroupIds?: string[]
  }
>

export interface FormData {
  allPartitions: boolean
  allActionPlans: boolean
  customerId: string
  contact: {
    name: string
    phone?: {
      provinceCode: number
      number: number
      type: PhoneType
    }
    role?: string
    email?: string
    ubiAppUser: boolean
  }
  accounts: Account[]
  messaging?: {
    events?: EventConfiguration
    reports?: ReportScheduleConfiguration
  }
}

export type Icons = { name: IconName; describe: string; on?: boolean }
